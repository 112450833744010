export const initialState = {
    is_loading: true,
    current_page: 1,
    details: {},
    is_loading_running_jobs: true,
    running_jobs: {},
    running_jobs_current_page: 1,
    running_jobs_total_pages: 0,
    running_jobs_total_jobs: 0,
    running_jobs_search_value: "",
    is_open_running_jobs_details_modal: false,
    selected_running_job: {},
    is_loading_all_ftms: true,
    all_ftms_total_pages: 0,
    total_ftms: 0,
    ftms_list: {},
    all_ftms_current_page: 1,
    all_ftms_search_value: "",
}