import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from "@mui/material/Tooltip";
import Icon from '@mui/material/Icon';

import { Context } from "../provider";
import { getFtmVerifyStatus } from "../../utils";
import { fetchAllFtms } from "../service";

const FtmTable = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    useEffect(() => {
        fetchAllFtms(context.state.all_ftms_current_page, context.state.all_ftms_search_value, context.dispatch);
    }, [context.state.all_ftms_current_page, context.state.all_ftms_search_value]);
    
    return (
        <Box className="table-responsive p-0">
            <Table className="table align-items-center">
                <TableHead>
                    <TableRow>
                        {[
                            'Tradesperson',
                            'Contact Number',
                            'Email',
                            'GPS device',
                            'Availability',
                            'Status'
                        ].map((text, index) => (
                            <TableCell 
                                key={index} 
                                className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                                    {text}
                            </TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {context.state.ftms_list.length > 0 ? (
                        <>
                            {context.state.ftms_list.map((ftm, index) => (
                                <TableRow key={index}>
                                    <TableCell className="align-middle">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 233,
                                                width: 350,
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                            }}
                                            className="avatar avatar-sm me-3 border-radius-lg"
                                            alt="user1"
                                            src={ftm.ftm_profile_image_path || "/assets/img/lock_user.png"}
                                        />
                                        {ftm.ftm_forename} {ftm.ftm_surname}    
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        {ftm.ftm_contact_number}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        {ftm.ftm_email}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        {ftm.ftm_device_id}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <Chip label={ftm.ftm_is_online === 1 ? 'Online' : 'Offline'} color={ftm.ftm_is_online === 1 ? 'success' : 'secondary'} />
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <Tooltip title={getFtmVerifyStatus(ftm.ftm_is_verify).title}>
                                            <Icon 
                                                className="material-icons font-weight-bold"
                                                color={getFtmVerifyStatus(ftm.ftm_is_verify).color}
                                                style={{fontSize: '25px'}}
                                            >
                                                {getFtmVerifyStatus(ftm.ftm_is_verify).icon}
                                            </Icon>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <VisibilityIcon 
                                            style={{cursor: 'pointer'}}
                                            onClick={() => navigate(`/ftm/view/${ftm.ftm_id}`)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6}>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};

export default FtmTable;