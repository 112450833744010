export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                details: action.details,
                is_loading: false,
                // is_loading_running_jobs: false
            }; 
        case "IS_LOADING_RUNNING_JOBS":
            return {
                ...prevState,
                is_loading_running_jobs: action.is_loading_running_jobs,
            };
        case "SET_RUNNING_JOBS":
            return {
                ...prevState,
                is_loading_running_jobs: false,
                running_jobs_total_pages: action.total_pages,
                running_jobs_total_jobs: action.job_count,
                running_jobs: action.running_jobs,
            };
        case "UPDATE_RUNNING_JOBS_CURRENT_PAGE":
            return {
                ...prevState,
                running_jobs_current_page: action.running_jobs_current_page,
            };
        case "SET_RUNNING_JOBS_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                running_jobs_search_value: action.running_jobs_search_value,
            };
        case "IS_OPEN_RUNNING_JOBS_DETAILS_MODAL":
            return {
                ...prevState,
                selected_running_job: action.selected_running_job,
                is_open_running_jobs_details_modal: action.is_open_running_jobs_details_modal,
            };
        case "IS_LOADING_ALL_FTMS":
            return {
                ...prevState,
                is_loading_all_ftms: action.is_loading_all_ftms,
            };
        case "SET_ALL_FTMS":
            return {
                ...prevState,
                is_loading_all_ftms: false,
                all_ftms_total_pages: action.all_ftms_total_pages,
                total_ftms: action.total_ftms,
                ftms_list: action.ftms_list,
            };
        case "UPDATE_ALL_FTMS_CURRENT_PAGE":
            return {
                    ...prevState,
                    all_ftms_current_page: action.all_ftms_current_page,
                };
        case "SET_ALL_FTMS_SEARCH_VALUE":
            return {
                ...prevState,
                all_ftms_current_page: 1,
                all_ftms_search_value: action.all_ftms_search_value,
            };
        default:
            throw new Error();
    }
}