import React, { useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { Context } from "../provider";
import FtmTable from "./ftmTable";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import { theme } from "../../theme";
import Search from "../../components/searchField";

const FtmsList = () => {
    const context = useContext(Context);

    return (
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-md-6">
                        <h6 className="text-white text-capitalize ps-3">ALL TRADESPERSONS</h6>
                    </div>
                    <div className="col-md-6">
                        <Search
                            onSearch = {(search_value) => {
                                context.dispatch({ type: "SET_ALL_FTMS_SEARCH_VALUE", all_ftms_search_value: search_value });
                            }}
                        />
                    </div>
                </div>
            }

            body={
                <FtmTable />
            }

            footer={
                <Stack spacing={2} style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}> 
                    <Pagination
                        count={context.state.all_ftms_total_pages}
                        page={context.state.all_ftms_current_page}
                        onChange={(event, newPage) => context.dispatch({
                            type: "UPDATE_ALL_FTMS_CURRENT_PAGE",
                            all_ftms_current_page: newPage
                        })}
                        color={theme.main}
                    />
                </Stack>
            }
        />
    );
};

export default FtmsList;